export const SUBJECT_TYPE = {
    wtb:    1,
    wts:    2,
};

export const SUBJECT_PREFIX_TYPE = {
    default: 'select',
    wts: 'wts',
    wtb: 'wtb'
};

export const TARGET_PRICE_TYPE = {
    lot:    1,
    item:   2,
};

export const BULK_LOT_TYPE = {
    default: 'select',
    item: '0',
    lot: '1'
};

export const PRICE_NEGOTIATION_TYPE = {
    negotiable: '1',
    firm: '0'
};

export const CURRENCY = {
    usd: 'usd',
    eur: 'eur'
};

export const LISTING_ENHANCEMENT_OPTIONS = {
    regular: '0',
    premium: '10',
    sponsored: '50',
    sponsoredTop: '100'
};

export const LISTING_DURATION = {
    regular: '0',
    middle: '1',
    long: '2'
};

export const LISTINGS_CONTAINER_ID = "sell";

export const LISTINGS_MOBILE_BREAKPOINT = 767;

export const LISTING_CLASSES = {
    full: 'listing--full',
    line: 'listing--line',
    grid: 'listing--grid'
};

export const LISTING_STYLES = {
    full: 'full',
    line: 'line',
    grid: 'grid'
};

// listing width + horizontal margin x2
export const LISTING_GRID_WIDTH = 245 + 10;

export const LISTING_PREMIUM_TYPE = {
    regular: 0,
    premium: 1,
    sponsored: 50,
    sponsoredTop: 100
};
