import {TARGET_PRICE_TYPE, SUBJECT_TYPE, CURRENCY} from "../modules/members/listings/create/js/constants";

export function scrollToElement($element, time, padTop) {
    var startTop = null;

    if (!$element) {
        // TODO: show error in log or console or elsewhere
        return;
    }

    if (!time) {
        time = 1000;
    }

    if (!padTop) {
        startTop = $element.offset().top;
    } else {
        startTop = $element.offset().top - padTop; 
    }

    $('html, body').animate({
        scrollTop: startTop
    }, time);
}

export function toggleTwoElms(hides, shows) {
    $(hides).hide();
    $(shows).show();
};

export function getEmailPattern() {
    return /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/;
}

export function doEmailPatternTest(strToTest) {
    var foundPattern = false;
    var emailpattern = getEmailPattern();
    if (emailpattern.test(strToTest) !== false) {
        foundPattern = true;
    }
    return foundPattern;
}

export function doPatternTestFromElement($element) {
    var foundPattern = true;
    if ($element.attr('data-pattern') && !(new RegExp($element.attr('data-pattern')).test($element.val()))) {
        foundPattern = false;
    }
    return foundPattern;
}

export function getPatternMessageFromElement($element) {
    return $element.attr('data-pattern-message') ? $element.attr('data-pattern-message') : 'Value is incorrect.';      
}

export function getContactPatterns() {
    return [
        /^(?=.*\bskype\b).*$/gi,
        /^(?=.*\bwhatsapp\b).*$/gi,
        /^(?=.*\bcall)(?=.*\bme\b).*$/gi,
        /^(?=.*\bemail)(?=.*\bme\b).*$/gi,
        /^(?=.*\bdot)(?=.*\bcom\b).*$/gi,
        /^(?=.*\b\.com\b).*$/gi,
    ];
}

export function doContactInfoTest(strToTest) {
    var foundPattern = false;

    $.each(getContactPatterns(), function(i, wp) {
        var patternToTest = wp.test(strToTest);
        if (patternToTest) {
            foundPattern = true; 
            return false;
        }
    });
    
    return foundPattern;
}

export function getCtaFromElement(elm) {
    return $(elm).data('cta');
}

export function randomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function addCommasToNumber(number) {
    number += '';
    var x = number.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export function removeCommasFromNumber(num) {
    var x = num.toString();
    x = x.replace(/\,/g,'');
    return Number(x);
}

export function roundToDecimals(value, decimals) {
    var x = removeCommasFromNumber(value);
    return Number(Math.round(x +'e'+ decimals) +'e-'+ decimals).toFixed(decimals);
}

export function isNumberFloats(mxv) {
    return +mxv === mxv && (!isFinite(mxv) || !!(mxv % 1))
}

export function copyToClipboard(htmlElement, callback) {
    if (htmlElement.tagName.toUpperCase() !== 'INPUT' && htmlElement.tagName.toUpperCase() !== 'TEXTAREA') {
        console.log('Element should be <input> or <textarea>', htmlElement);
        return;
    }

    htmlElement.select();
    htmlElement.setSelectionRange(0, 9999);
    document.execCommand('copy');

    if (typeof callback === 'function') {
        callback();
    }
}

export function addEllipsis(string, length) {
    if (!string || string === '' || !length) {
        return string;
    }

    if (string.length <= length) {
        return string;
    }

    return string.slice(0, length).trim() + '...';
}

export function maskEmailsInText(txt) {
    var re = new RegExp(String.fromCharCode(160), "g");
    txt = txt.replace(re, " ");
    txt = txt.replace(/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi, '[email withheld]');
    return txt;
}

export function format_price(price, showCents, currency) {
    var fPrice = '';
    var strP = price.toString();
    var intP = parseFloat(strP);

    var inPFlt = intP.toFixed(2);
    var strPFlt = inPFlt.toString();
    var isFlZero = strPFlt.indexOf(".00");

    if (isFlZero !== -1) {
        fPrice = showCents === true ? addCommasToNumber(intP.toFixed(2)) : addCommasToNumber(intP);
    } else {
        fPrice = addCommasToNumber(intP.toFixed(2));
    }

    if (!!currency) {
        fPrice = currency.toString() + fPrice;
    }

    return fPrice;
}

export function stringToBooleanVal(string) {
    switch(string.toString().toLowerCase().trim()){
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(string);
    }
}

export function stringSpaceToDashLowerCase(string) {
    return string.replace(/\s+/g, '-').toLowerCase();
}

export function setElmAttributes(elm, attrs) {
    Object.keys(attrs).forEach(key => elm.setAttribute(key, attrs[key]));
}

export function load_image(gid, image_path, image_alt) {
    var img = new Image();
    $(img)
        .on("load", function() {
            $('#item-' + gid).find('.listing__image-link').append(this);
        })
        .attr('src', image_path)
        .attr('alt', image_alt)
        .attr('width', '70')
        .attr('height', '70')
        .addClass('listing__image-thumb');
}

export function getPriceHTML(item) {
    var priceFormatted = format_price(item.price);
    var currency = item.currency && item.currency === CURRENCY.eur ? '€' : '$';

    if (item.subject_typeid === SUBJECT_TYPE.wtb) {
        return '<strong class="tl-color--wtb">' + currency + priceFormatted + '</strong> each';
    } else if (parseInt(item.target_price_typeid[0]) === TARGET_PRICE_TYPE.lot) {
        return '<strong class="tl-color--wts">' + currency + priceFormatted + '</strong> / lot';
    } else {
        return '<strong class="tl-color--wts">' + currency + priceFormatted + '</strong> each';
    }
}

export function getQuantityHTML(item) {
    let quantity = addCommasToNumber(item.quantity);

    if (item.subject_typeid === SUBJECT_TYPE.wtb) {
        return '<strong class="tl-color--wtb">' + quantity + '</strong> Needed'
    } else {
        if (parseInt(item.target_price_typeid[0]) === TARGET_PRICE_TYPE.lot) {
            return 'Lot of <strong class="tl-color--wts">' + quantity + '</strong>';
        } else {
            return '<strong class="tl-color--wts">' + quantity + '</strong> Available';
        }
    }
}

export function getPriceQuantityHTML(item) {
    const priceFormatted = format_price(item.price);
    const qtyFormatted = addCommasToNumber(item.quantity);

    if (item.subject_typeid === SUBJECT_TYPE.wtb) {
        return '<strong class="tl-color--wtb">$' + priceFormatted + '</strong> each';
    } else if (parseInt(item.target_price_typeid[0]) === TARGET_PRICE_TYPE.lot) {
        return '<strong class="tl-color--wts">$' + priceFormatted + '</strong> / lot of <strong class="tl-color--wts">' + qtyFormatted + '</strong>';
    } else {
        return '<strong class="tl-color--wts">$' + priceFormatted + '</strong> each';
    }
}

export function getItemShippingCountry(item) {
    let result = '';

    if (typeof item.ships_from_country !== 'undefined' && item.ships_from_country.length > 0) {
        const title = (item.subject_typeid === SUBJECT_TYPE.wtb ? 'Destination ' : 'Ships from ') + item.ships_from_country;
        result = '<span class="flag flag-' + item.ships_from_country_code + '" data-toggle="tooltip" title="' + title + '"></span>';
    }

    return result;
}

export function getRepliesLabelHTML(item) {
    if (!item || !item.is_logged_in) {
        return;
    }

    if (item.self && item.hasReply || item.replied) {
        let result = '<span class="listing__replies">';

        if (item.self && item.hasReply) {
            result += '&nbsp;&nbsp;<strong><a href="' + item.inquiriesUrl + '">(' + item.replyCount + ' <span class="fas fa-envelope color-grey fs-12" aria-hidden="true"></span>)</a></strong>';
        }

        if (item.replied) {
            result += '<strong><a href="' + item.messageReplyUrl + '">your inquiry</a></strong>';
        }

        result += '</span>';
        return result;
    }

    return '';
}

export function getRepliesHTML(item) {
    if (!item) {
        return;
    }

    if (parseInt(item.repliesNumber) > 0) {
        return '<a href="javascript:void(0);">' + parseInt(item.repliesNumber) + ' inquiries</a>';
    } else {
        return 'no inquiries';
    }
}

export function strip(html){
    if (!html || html.length === 0) {
        return '';
    }

    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export function clean(txt) {
    if (!txt || txt.length === 0) {
        return '';
    }

    var re = new RegExp(String.fromCharCode(160), "g");
    txt = txt.replace(re, " ");
    txt = txt.replace(/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi, '[email witheld]');
    return txt;
}

export function pluralize(cnt,s,p){
    if (cnt > 1 || cnt === 0) {
        return p;
    } else {
        return s;
    }
}

export function replyCount(id) {
    if (typeof created != 'undefined') {
        if (created.hasOwnProperty(id + "")) {
            return created[id + ""]['replies'];
        }
    }
    return 0;
}

export function getURLParameterValue(sPageURL, sParam) {
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1];
        }
    }
    return null;
}

export function setURLParameter(url, paramName, paramValue) {
    if (!url || !paramName) return;

    var result = url.split('?');
    var paramsList = result[1];

    if (!paramsList) return;

    if (paramsList.indexOf(paramName) === -1) {
        result[1] += '&' + paramName + '=' + paramValue.toString();
    } else {
        var re = new RegExp(paramName + '=[0-9a-zA-Z\-]+', 'g');
        result[1] = paramsList.replace(re, paramName + '=' + paramValue);
    }

    return result.join('?');
}

// TODO: test with:
// source: '    Lorem!? IPSUM - dolor; sit't amet, consectetur: adipiscing, elit...   '
// result: 'lorem-ipsum-dolor-sitt-amet-consectetur-adipiscing-elit'
export function slugify(string) {
    if (!string) {
        return;
    }

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^a-z0-9\-]/g, '') // Replace special characters with ''
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}

// TODO: duplicated in data.js
export function getListingUrl(item, slug_subject_type, cat_tree, messageSubject) {
    var rtn = '/' + slug_subject_type.toLowerCase() + cat_tree.toLowerCase() + '/' + messageSubject.toLowerCase() + '?id=' + item.tradefeed_id;

    /*
    if (item.is_expired) {
        rtn += '&expired=true';
    } else {
        rtn += '&active=true';
    }
    */

    return rtn;
}

export function isPassword(value) {
    const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!@#$%^&*()+=,.<>?_-]+)$/;
    const letters = /^(.*[a-zA-Z]+.*)$/;
    const numbers = /^(.*[0-9]+.*)$/;

    if (value === '') {
        return false;
    }

    if (value.length < 8 || value.length > 24) {
        return false;
    }

    if (!letters.test(value) || !numbers.test(value) || !pattern.test(value)) {
        return false;
    }

    return true;
}

export function sanitizeTextInput(input) {
    return framework.removeHTML(framework.strip(input));
}

export function getDaysFromMilliseconds(ms) {
    return Math.floor(ms / (1000 * 60 * 60 * 24));
}

export function addCommas(nStr){
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export function boldString(str, find) {
    if (!str) {
        return "";
    }

    try {
        var re = new RegExp(find, 'gi');
        var matches = str.match(re);
        if (matches) {
            $.each(matches, function(i, match) {
                //var rex = new RegExp("[^\\>]?"+match+"[^\\<]", 'g');
                try {
                    var rex = new RegExp(match, 'g');
                    str = str.replace(rex, '<b>' + match + '</b>');
                } catch (e) {
                    // regex error
                }
            });
        }
    } catch (e) {
        // regex error
    }

    return str;
}

export function blueString(str, find, text) {
    if (!text) {
        return "";
    }

    try {
        var re = new RegExp(find, 'gi');
        var matches = text.match(re);
        if (matches) {
            str = '<span class="tl-color--tertiary">' + str + '</span>';
        }
    } catch (e) {
        // regex error
    }

    return str;
}

export function highlightString(str, find) {
    if (!str) {
        return "";
    }

    if (str.length < 3) {
        return str;
    }

    try {
        var re = new RegExp(find, 'gi');
        var matches = str.match(re);
        if (matches) {
            $.each(matches, function(i, match) {
                try {
                    //var rex = new RegExp("[^\\>]?"+match+"[^\\<]", 'g');
                    var rex = new RegExp(match, 'g');
                    str = str.replace(rex, '<span class="tl-highlighted-light">' + match + '</span>');
                } catch (e) {
                    // regex error
                }
            });
        }
    } catch (e) {
        // regex error
    }

    return str;
}

export function doTextHighlight(docPart,textToHighlight){
    var container = document.getElementById(docPart);
    InstantSearch.highlight(container, textToHighlight);
}

export function isSiteOnline(url,callback) {
    // try to load favicon
    var url_val = url.trim().toLowerCase();

    $('#loading').show();
    $('#red_crossimg').hide();
    $('#check_img').hide();
    $("#loading").html("<img src='/images/ajax/arrow-spinner-128.gif' height='16px;' width='16px;'>");

    if(url_val == 'https://none'){
        callback(true);
    }
    else{
        var timer = setTimeout(function(){
            // timeout after 2 seconds
            callback(false);
        },5000)

        var img = document.createElement("img");
        img.onload = function() {
            clearTimeout(timer);
            callback(true);
        }

        img.onerror = function() {
            clearTimeout(timer);
            callback(false);
        }

        img.src = url+"/favicon.ico";
    }
}

function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
}

export function guid() {
    return (S4()+S4()+""+S4()+""+S4()+""+S4()+""+S4()+S4()+S4());
}

export function url(e) {
    return window.$baseurl + '/' + e;
}

export function isAllowedFileExtension(fileName, allowedExtArr) {
    var arr = fileName.split('.');
    var ext = arr[arr.length - 1];
    return allowedExtArr.indexOf(ext) !== -1;
}

export function checkWebsite(URL, success, error) {
    var lURL = URL.toLowerCase();
    var pings = 'https://radar.' + window.this_site_domain + '/feeds/stats/urls/pingUrl.cfm?urlPing=https://' + URL;

    if (lURL === '' || lURL === 'none') {
        return;
    }

    $.ajax({
        type: "GET",
        url: pings,
        success: function(r) {
            if (r.valid) {
                if (typeof success == 'function') {
                    success();
                }
            } else {
                if (typeof error == 'function') {
                    error();
                }
            }
        },
        error: function() {
            if (typeof error == 'function') {
                error();
            }
        }
    });
}

export function getFormData($form) {
    const rawData = $form.serializeArray();
    let result = {};

    $.map(rawData, function(n, i){
        result[n['name']] = n['value'];
    });

    return result;
}

export function deleteFilterLine(filter_str, field) {
    const index = filter_str.findIndex((item, i) => {
        return item.indexOf(field) !== -1;
    });

    if (index !== -1) {
        delete filter_str[index];
    }

    return filter_str;
}

export function decodeHTMLEntities(string) {
    string += '';

    if (!string || string.length === 0) {
        return;
    }

    const textArea = document.createElement('textarea');
    textArea.innerHTML = string;
    return textArea.value;
}
